import blog1 from '../assets/png/blog1.png'

export const blogData = [
    {
        id: 1,
        title: 'Top 5 things to consider while designing micro services — Better Architecting',
        description: 'Micro-service design is a familiar concept that is one of the buzzing words as soon as you enter the tech industry but I believe it is worth the buzz.',
        date: 'Jul 26, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:1280/0*pG8UqRlDEMS22Pta.gif',
        url: 'https://m-chetandwarkani.medium.com/top-5-things-to-consider-while-designing-micro-services-better-architecting-c7c9731bd400'
    },
    {
        id: 2,
        title: 'Scaling your backend service — System Design',
        description: 'In this blog, I am going to scale a backend service from a simple monolithic architecture design to a complex design that can handle a lot of concurrent requests....',
        date: 'Dec 30, 2021',
        image: blog1,
        url: 'https://m-chetandwarkani.medium.com/scaling-your-backend-service-system-design-158ba107d0d8'
    },
    {
        id: 3,
        title: 'Setting up Load Balancer — System Design',
        description: 'As and when the user base of our app expands, the requests/second hitting the server starts increasing and now our server has to serve huge requests every day, and here’s the point where integrating load balancer comes into the ',
        date: 'Jan 5, 2022',
        image: 'https://miro.medium.com/max/4800/1*LQAE5wSycdschz3AIA1Wxg.png',
        url: 'https://m-chetandwarkani.medium.com/setting-up-load-balancer-system-design-e29ed2b4a957'
    },
    {
        id: 4,
        title: 'Setting up Docker and debugging inside the Docker container',
        description: 'In this blog, I am going to help you understand what’s Docker in simple terms and also help you in running the whole docker setup in your local system.',
        date: 'Jan 12, 2022',
        image: 'https://miro.medium.com/max/4800/1*J_bdx68-cRL7tmYxPIEL0w.png',
        url: 'https://m-chetandwarkani.medium.com/setting-up-docker-and-debugging-inside-the-docker-container-system-design-ee6acfbb9b7b'
    },
    {
        id: 8,
        title: 'Setting up Kubernetes in your local.',
        description: 'In this blog we are going to setup Kubernetes in order to manage our docker containers.',
        date: 'Jan 19, 2022',
        image: 'https://miro.medium.com/max/4800/1*2TKzE_SDNGy54gA9Imridw.webp',
        url: 'https://m-chetandwarkani.medium.com/setting-up-kubernetes-in-your-local-system-design-part-4-32b061e0c041'
    },
    {
        id: 9,
        title: 'Setting up Load balancer, Docker, Kubernetes in your local',
        description: 'In this blog, I am going to connect Load Balancer, Docker and use Kubernetes to scale the app for handling huge number of incoming request.',
        date: 'Jan 25, 2022',
        image: 'https://miro.medium.com/max/4800/1*fmEEkaNaKeCH8PEDDebbPg.png',
        url: 'https://m-chetandwarkani.medium.com/setting-up-load-balancer-docker-kubernetes-in-your-local-system-design-part-5-5a393718d115'
    },
    {
        id: 6,
        title: 'Understanding Data Structures and algorithms used in order to select the right framework for a scalable system',
        description: 'Well, Yeah! We do need to understand certain DS before we start our further course because a lot of frameworks works on these data structure.',
        date: 'Feb 25, 2022',
        image: 'https://miro.medium.com/max/4800/1*Y5SevywS15lNs8-1pAna6A.jpeg',
        url: 'https://m-chetandwarkani.medium.com/understanding-data-structures-and-algorithms-used-in-order-to-select-the-right-framework-for-a-2132769f41b9'
    },
    {
        id: 7,
        title: 'Node.js performance optimization with clustering : Multiprocessing',
        description: 'While scaling the web app it is important to think about app performance when you reach millions of users or else it leads to a big chaos when your user base starts growing.',
        date: 'Apr 2, 2022',
        image: blog1,
        url: 'https://m-chetandwarkani.medium.com/node-js-performance-optimization-with-clustering-multiprocessing-system-design-part-7-2a59e69530b4'
    },
    {
        id: 4,
        title: 'Clean Recycler view with MVVM + LiveData + Repository+ DataBinding pattern',
        description: 'MVVM+ LiveData + Repository + Databinding is one of the great architecture pattern to adopt when you are choosing a framework in android.',
        date: 'May 17, 2020',
        image: 'https://i.ibb.co/tLC3r08/68747470733a2f2f64726976652e676f6f676c652e636f6d2f75633f6578706f72743d766965772669643d315a7167526542.jpg',
        url: 'https://m-chetandwarkani.medium.com/clean-recycler-view-with-mvvm-livedata-repository-databinding-pattern-cde9aa204874'
    },
    {
        id: 5,
        title: 'Clean Hello World App— MVVM + LiveData + DataBinding',
        description: 'In this article, I am going to talk about one of the famous MVVM design patterns and help you develop a small hello world app that follows the clean architecture design principle.',
        date: 'Jun 12, 2021',
        image: 'https://i.ibb.co/tLC3r08/68747470733a2f2f64726976652e676f6f676c652e636f6d2f75633f6578706f72743d766965772669643d315a7167526542.jpg',
        url: 'https://m-chetandwarkani.medium.com/clean-hello-world-app-mvvm-livedata-databinding-f4f657cbf5fe'
    },
    {
        id: 10,
        title: 'Low Level Design Pattern - Creational',
        description: 'Creational Design Pattern deals with the way you can structure your code base in terms of creating objects in order to provide flexibility on object creations, API versioning and reusing existing code.',
        date: 'Jan 17, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*89uq50CBaaQYhfA649EIag.png',
        url: 'https://m-chetandwarkani.medium.com/clean-hello-world-app-mvvm-livedata-databinding-f4f657cbf5fe'
    },
    {
        id: 11,
        title: 'Low Level Design Pattern - Structural',
        description: 'Structural Design Pattern helps us to understand how to create a complex objects from a simple initial base object while making sure that object is greatly efficient.',
        date: 'Jan 17, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*89uq50CBaaQYhfA649EIag.png',
        url: 'https://m-chetandwarkani.medium.com/design-pattern-structural-low-level-system-design-decorator-proxy-flyweight-design-pattern-b407517b7ac8'
    },
    {
        id: 12,
        title: 'LLD - Behavioural',
        description: 'Behavioural design pattern is concerned with identifying common communication pattern between objects and separating them into different classes which can be plugged in any of the other objects and help in inter-communicating.',
        date: 'Jan 18, 2023',
        image: 'https://miro.medium.com/v2/resize:fit:4800/format:webp/1*89uq50CBaaQYhfA649EIag.png',
        url: 'https://m-chetandwarkani.medium.com/design-pattern-behavioural-low-level-system-design-strategy-observer-state-design-7e7386b6600a'
    }
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/