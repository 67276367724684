export const experienceData = [
    {
        id: 1,
        company: 'SurveySparrow',
        jobtitle: 'Lead Product Engineer',
        startYear: 'May 2022',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Exeter Pvt. Ltd.',
        jobtitle: 'Senior Software Engineer',
        startYear: 'Aug 2020',
        endYear: 'May 2022'
    },
    {
        id: 3,
        company: 'Kaleidofin',
        jobtitle: 'Software Developer',
        startYear: 'Oct 2019',
        endYear: 'Aug 2020'
    },
    {
        id: 4,
        company: 'MyKarma',
        jobtitle: 'Full Stack Developer',
        startYear: 'Oct 2018',
        endYear: 'Sept 2019'
    },
]