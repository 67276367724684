
export const headerData = {
    name: 'Chetan Dwarkani',
    title: "Hello! I am",
    desciption1: "Hello World! ",
    desciption2:"I am a passionate programmer in my daily life, a quick learner with a self-learning attitude. I love to learn and explore new technologies.",
    image: 'https://i.ibb.co/KXDv0ct/IMG-0125.jpg',
    imagebw: 'https://i.ibb.co/KXDv0ct/IMG-0125.jpg',
    
}
