export const educationData = [
    {
        id: 1,
        institution: 'SRM Easwari Engineering College',
        course: 'Bachelor Degree ( 44th State Rank )',
        startYear: '2013',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'Agarwal Vidyalaya',
        course: 'Higher Secondary ( 93% )',
        startYear: '2011',
        endYear: '2013'
    },
    {
        id: 3,
        institution: 'Agarwal Vidyalaya',
        course: 'Secondary School ( 93% )',
        startYear: '2007',
        endYear: '2011'
    },
]