import criccrak from '../assets/png/cricrak.png'
import onenews from '../assets/png/onenews.png'
import gamesadda from '../assets/png/gamesadda.png'
import smym from '../assets/png/smym.png'

export const projectsData = [
    {
        id: 1,
        projectName: 'CricCrak',
        projectDesc: 'This project is about cricket score live updates & cricket news.',
        tags: ['MERN, Android, Docker, AWS, Kubernetes' ],
       
        demo: 'https://play.google.com/store/apps/details?id=com.dwarsoft.criccrak&hl=en_IN&gl=US',
        image: criccrak
    },
    {
        id: 2,
        projectName: 'One News',
        projectDesc: 'An app to read curated News from various categories and sources.',
        tags: ['Postgresql, Docker, AWS, Nodejs, React, Android, Kubernetes' ],

        demo: 'https://play.google.com/store/apps/details?id=com.dwarsoftgames.onenews&hl=en_IN&gl=US',
        image: onenews
    },
    {
        id: 3,
        projectName: 'Games Adda',
        projectDesc: 'GamesAdda is a stress buster game that lets you earn coins while you play the games and compare your standing with the world.',
        tags: ['MySQL, Docker, AWS, Nodejs, React, Android, Kubernetes' ],
        demo: 'https://play.google.com/store/apps/details?id=com.dwarsoftgames.gamesadda&hl=en_IN&gl=US',
        image: gamesadda
    },
    {
        id: 4,
        projectName: 'SMYM Chennai',
        projectDesc: 'This is the Official app of SMYM Chennai. It is made with the purpose of letting all the members of the samaj to get updates with recent news and events happenings in SMYM Chennai.',
        tags: ['MySQL, Docker, AWS, Nodejs, React, Android, Kubernetes', 'elastic' ],

        demo: 'https://play.google.com/store/apps/details?id=com.dwarsoftgames.smymchennai&hl=en_IN&gl=US',
        image: smym
    }
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/